const routes = {
  path: '/gooddata',
  component: () => import('gooddata/layouts/GooddataAuthLayout.vue'),
  children: [
    {path: '/gooddata', redirect: '/gooddata/jobs'},
    {path: '/gooddata/message', component: () => import('src/pages/MessagePage.vue')},
    {path: '/gooddata/home', component: () => import('gooddata/pages/job/Jobs.vue')},
    {path: '/gooddata/jobs', component: () => import('gooddata/pages/job/Jobs.vue')},
    {path: '/gooddata/jobs/:jobId', component: () => import('gooddata/pages/job/Job.vue')},
    {path: '/gooddata/objects/:objectId', component: () => import('gooddata/pages/datamasking/Object.vue')},
    {path: '/gooddata/jobs/:jobId/runs/:runId', component: () => import('gooddata/pages/run/Run.vue')},
  ],
};

export default routes;
