const routes = {
  path: '/metasync',
  component: () => import('metasync/layouts/MetasyncAuthLayout.vue'),
  children: [
    {path: '/metasync', redirect: '/metasync/home'},
    {path: '/metasync/message', component: () => import('src/pages/MessagePage.vue')},
    {path: '/metasync/view-metadata', component: () => import('metasync/pages/metadata/ViewMetadata.vue')},
    {path: '/metasync/view-comp', component: () => import('metasync/pages/metadata/ViewComp.vue')},
    {path: '/metasync/compare-metadata', component: () => import('metasync/pages/metadata/CompareMetadata.vue')},
    {path: '/metasync/select-metadata', component: () => import('metasync/pages/metadata/SelectMetadataPage.vue')},
    // {
    //   path: '/metasync/view-metadata-comp',
    //   component: () => import('metasync/pages/metadata/ViewMetadataCompPage.vue'),
    // },
    {path: '/metasync/home', component: () => import('metasync/pages/deployment/Home.vue')},
    {path: '/metasync/jobs', component: () => import('metasync/pages/job/Jobs.vue')},
    {path: '/metasync/cicd', component: () => import('metasync/pages/cicd/Jobs.vue')},
    {path: '/metasync/cicd/:jobId', component: () => import('metasync/pages/cicd/Job.vue')},
    {path: '/metasync/jobs/:jobId', component: () => import('metasync/pages/job/Job.vue')},
    {path: '/metasync/jobs/:jobId/runs/:runId', component: () => import('metasync/pages/run/Run.vue')},
    {path: '/metasync/metadata-alerts', component: () => import('metasync/pages/metadataAlert/MetadataAlerts.vue')},
    {path: '/metasync/deployments', component: () => import('metasync/pages/deployment/Home.vue')},
    {
      path: '/metasync/deployments/:deploymentId/tasks/new',
      component: () => import('metasync/pages/deployment/task/DeploymentTaskPage.vue'),
    },
    {
      path: '/metasync/deployments/:deploymentId?edit',
      component: () => import('metasync/pages/deployment/task/DeploymentTaskPage.vue'),
    },
    {
      path: '/metasync/deployments/:deploymentId/tasks/:taskId/edit',
      component: () => import('metasync/pages/deployment/task/DeploymentTaskPage.vue'),
    },
    {path: '/metasync/deployments/:deploymentId', component: () => import('metasync/pages/deployment/Deployment.vue')},
    {
      path: '/metasync/deployments/:deploymentId/runs/:runId',
      component: () => import('metasync/pages/deployment/run/Run.vue'),
    },
    {path: '/metasync/setup', component: () => import('metasync/pages/setup/Setup.vue')},
    {
      path: '/metasync/setup/pipelines/:pipelineId',
      component: () => import('metasync/pages/setup/pipeline/Pipeline.vue'),
    },
    {
      path: '/metasync/setup/sfdc-orgs/:sfdcOrgId',
      component: () => import('metasync/pages/setup/sfdcOrg/SfdcOrg.vue'),
    },
    {
      path: '/metasync/setup/pipeline-orgs/:pipelineOrgId',
      component: () => import('metasync/pages/setup/pipelineOrg/PipelineOrg.vue'),
    },
    {
      path: '/metasync/setup/git-repos/:gitRepoId',
      component: () => import('metasync/pages/setup/gitRepo/GitRepo.vue'),
    },
    {path: '/metasync/tools/commits', component: () => import('metasync/pages/tools/commit/Commits.vue')},
    {path: '/metasync/tools/commitFiles/new', component: () => import('metasync/pages/tools/commit/CommitJobPage.vue')},
    {
      path: '/metasync/tools/commitFiles/clone',
      component: () => import('metasync/pages/tools/commit/CommitJobPage.vue'),
    },
    {path: '/metasync/tools/commits/:commitId', component: () => import('metasync/pages/tools/commit/Commit.vue')},
    // {path: '/metasync/jobs/:jobId/runs/:runId', component: () => import('metasync/pages/run/Run.vue')},

    // {path: '/metasync/test', component: () => import('metasync/pages/metadata/Test.vue')},
  ],
};

export default routes;
