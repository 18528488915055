const routes = {
  path: '/realtask',
  component: () => import('../layouts/RealtaskAuthLayout.vue'),
  children: [
    {path: '/realtask', redirect: '/realtask/jobs'},
    {path: '/realtask/message', component: () => import('src/pages/MessagePage.vue')},
    {path: '/realtask/home', component: () => import('../pages/jobs/Jobs.vue')},
    {path: '/realtask/jobs', component: () => import('../pages/jobs/Jobs.vue')},
    {path: '/realtask/jobs/:jobId', component: () => import('../pages/jobs/Job.vue')},
    {path: '/realtask/jobs/:jobId/runs/:runId', component: () => import('../pages/runs/Run.vue')},
    {path: '/realtask/datamask/playground', component: () => import('../pages/tasks/target/sfdcDataMask/DataMaskPlayground.vue')},
  ],
};

export default routes;
