import {RouteRecordRaw} from 'vue-router';
import gooddata from 'src/products/gooddata/router/routes';
import realsync from 'src/products/realsync/router/routes';
import realtask from 'src/products/realtask/router/routes';
import metasync from 'src/products/metasync/router/routes';

const routes: RouteRecordRaw[] = [
  // {
  //   path: '/account/login',
  //   component: () => import('layouts/MainLayout.vue'),
  //   children: [{path: '', component: () => import('pages/Index.vue')}],
  // },
  {
    path: '/account/login',
    component: () => import('../layouts/UnauthLayout.vue'),
    children: [
      {path: '', component: () => import('account/pages/login/Login2.vue')},
      {path: '/formulaplay', component: () => import('account/pages/formulaplay/FormulaPlay.vue')},
      {path: '/account/login2', component: () => import('account/pages/login/Login2.vue')},
      {path: '/account/signup', component: () => import('account/pages/login/Signup.vue')},
      {path: '/account/forgot-password', component: () => import('account/pages/login/ForgotPassword.vue')},
      {path: '/account/reset-password', component: () => import('account/pages/login/ResetPassword.vue')},
      {path: '/account/logout', component: () => import('account/pages/login/Logout.vue')},
      {path: '/message', component: () => import('src/pages/MessagePage.vue')},
      {path: '/account', redirect: '/setup/users'},
      {path: '/common/message', component: () => import('src/pages/MessagePage.vue')},
      {path: '/account/message', component: () => import('src/pages/MessagePage.vue')},
      {path: '/_status', component: () => import('src/pages/AppStatus.vue')},
      {path: '/_status2', component: () => import('src/pages/AppStatus2.vue')},
    ],
  },
  {
    path: '/account',
    component: () => import('account/layouts/AccountAuthLayout.vue'),
    children: [
      {path: '/account/home', component: () => import('account/pages/user/Users.vue')},
      {path: '/account/manage', redirect: '/setup/users'},
      {path: '/account/users', component: () => import('account/pages/user/Users.vue')},
      {path: '/account/users/:userId', component: () => import('account/pages/user/User.vue')},
      {path: '/account/groups', component: () => import('account/pages/group/Groups.vue')},
      {path: '/account/tenant', component: () => import('account/pages/tenant/Tenant.vue')},
      {path: '/account/envs', component: () => import('account/pages/env/Envs.vue')},
      {path: '/account/envs/:envId', component: () => import('account/pages/env/Env.vue')},
      {path: '/account/oauth-clients', component: () => import('account/pages/oauthClient/OauthClients.vue')},
    ],
  },
  {
    path: '/setup',
    component: () => import('account/layouts/AccountAuthLayout.vue'),
    children: [
      {path: '/setup', redirect: '/setup/users'},
      {path: '/setup/home', component: () => import('account/pages/user/Users.vue')},
      {path: '/setup/manage', component: () => import('account/pages/profile/ManageAccount.vue')},
      {path: '/setup/users', component: () => import('account/pages/user/Users.vue')},
      {path: '/setup/users/:userId', component: () => import('account/pages/user/User.vue')},
      {path: '/setup/groups', component: () => import('account/pages/group/Groups.vue')},
      {path: '/setup/tenant', component: () => import('account/pages/tenant/Tenant.vue')},
      {path: '/setup/envs', component: () => import('account/pages/env/Envs.vue')},
      {path: '/setup/envs/:envId', component: () => import('account/pages/env/Env.vue')},
      {path: '/setup/oauth-clients', component: () => import('account/pages/oauthClient/OauthClients.vue')},
      {path: '/setup/sso', component: () => import('account/pages/sso/Ssos.vue')},
      {path: '/setup/jobs', component: () => import('account/pages/jobs/Job.vue')},
    ],
  },
  {
    path: '',
    component: () => import('src/layouts/AuthLayout.vue'),
    children: [
      {path: '', redirect: '/home'},
      {path: '/', redirect: '/home'},
      {path: '/test', component: () => import('pages/test/Test.vue')},
      {path: '/home', component: () => import('pages/home/Home.vue')},
      {path: '/profile', component: () => import('account/pages/profile/ManageProfile.vue')},
      {path: '/account/connections', component: () => import('account/pages/connection/Connections.vue')},
      {path: '/account/connections/:connId', component: () => import('account/pages/connection/Connection.vue')},
      {path: '/connections', component: () => import('account/pages/connection/Connections.vue')},
      {path: '/connections/:connId', component: () => import('account/pages/connection/Connection.vue')},
    ],
  },
  gooddata,
  realsync,
  realtask,
  metasync,
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
];

export default routes;
