const routes = {
  path: '/realsync',
  component: () => import('realsync/layouts/RealsyncAuthLayout.vue'),
  children: [
    {path: '/realsync/home', component: () => import('../pages/job/Jobs.vue')},
    {path: '/realsync/jobs', component: () => import('../pages/job/Jobs.vue')},
    {path: '/realsync/message', component: () => import('src/pages/MessagePage.vue')},
    {path: '/realsync/jobs/:jobId', component: () => import('../pages/job/Job.vue')},
    {path: '/realsync/jobs/:jobId/record', component: () => import('../pages/job/ViewData.vue')},
    {path: '/realsync/jobs/:jobId/record?id=:recordId', component: () => import('../pages/job/ViewData.vue')},
    {path: '/realsync/jobs/:jobId/query', component: () => import('../pages/job/QueryData.vue')},
    {path: '/realsync/objects/:objectId', component: () => import('../pages/object/Object.vue')},
    {path: '/realsync/restore-jobs/:restoreJobId', component: () => import('../pages/restore/RestoreJob.vue')},
    {path: '/realsync/jobs/:jobId?tab=restore', component: () => import('../pages/job/Job.vue')},
  ],
};

export default routes;
